import React from 'react';
import '../../styles/icons/whatsappButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/5492342514576"
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
       <FontAwesomeIcon icon={faWhatsapp}/>
    </a>
  );
};

export default WhatsAppButton;