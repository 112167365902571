import { useEffect, useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

function useProductsAdmin() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);

    const refetchProducts = async () => {

        try {
            setLoading(true);
  
            const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/admin/administrador`);

            if (!response.ok) {
                throw new Error(`Error al obtener productos: ${response.statusText}`);
            }

            const data = await response.json();

            console.log(data,'dataaaaaaaaaaa')
            setProducts(data); 
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refetchProducts();
    }, []);


    return { products, refetchProducts, loading, error};
}

export default useProductsAdmin;