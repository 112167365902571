import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useDeleteCart = () => {
  const [loadingDelete, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteCart = async (cartID) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carts/${cartID}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error eliminando el carrito');
      }

      localStorage.removeItem('cart');

      window.location.reload();
    } catch (err) {
        console.log(err,'error')
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return { deleteCart, loadingDelete, error };
};

export default useDeleteCart;