import React, { useState } from 'react';
import BreadCrumb from '../breadCrum/BreadCrum.jsx';
import '../../styles/faqs/faqs.css'; 

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: '¿Cómo puedo comprar en Indis?',
      answer: 'Explorá nuestros productos, agregálos al carrito y hacé clic en “FINALIZAR COMPRA” para completar la compra. Una vez confirmado el pago, recibirás un mail y/o WhatsApp confirmando tu compra y luego te enviaremos el número de seguimiento de tu envío.'
    },
    {
      question: '¿Es seguro comprar en Indis?',
      answer: 'Sí, tu compra es 100% segura. Usamos tecnología de encriptación para proteger tu información y no almacenamos datos de tus tarjetas.'
    },
    {
      question: '¿Qué métodos de pago aceptan?',
      answer: 'Aceptamos pagos con tarjetas de crédito y débito de Visa, Mastercard, Cabal, y Naranja X, entre otros. También ofrecemos opciones de pago con transferencia y otras plataformas locales.'
    },
    {
      question: '¿Cuánto cuesta el envío?',
      answer: (
        <>
          <p>El costo del envío depende de tu provincia. Al momento de hacer el pago, debés llenar el formulario para calcular tu costo de envío con tu código postal.</p>
          <p>Recordá que llevando 3 productos de la misma categoría, el costo del envío corre por nuestra cuenta ;)</p>
        </>
      )
    },
    {
      question: '¿Qué hago si tengo problemas con mi pedido?',
      answer: 'Si no podés acceder a tu descarga o tenés algún problema, contactanos con tu número de pedido y te ayudaremos a solucionarlo lo antes posible.'
    }
  ];
  
  return (
    <section className="faqs-section">
      <div className='backgroundFaqs'>
        <h2 className='faqs-title'>Preguntas Frecuentes</h2>
      </div>
      
      <div className='filterContainer'>
        <BreadCrumb />
      </div>

      <div className='faqs-container'>


        <div className="faqs-list">
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div 
                className="faq-question" 
                onClick={() => toggleAnswer(index)}
              >
                <h4>{faq.question}</h4>
                <span className="toggle-icon">
                  {activeIndex === index ? '−' : '+'}
                </span>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>

        <div className='faq-item'>
          <img 
            src={`logotypeRedondos.webp`} 
            alt="INDIS INDUMENTARIA" 
            className="company-logo"
          />
          <p>&copy; {new Date().getFullYear()} INDIS Ind.</p>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
