import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/detailProduct/detailProduct.css';
import BreadCrum from '../breadCrum/BreadCrum';
import ProductGallery from './ProductGallery.jsx';
import { useProductContext } from '../../context/ProductContext';
import { useCart } from '../../context/cartContext.js';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../loading/Loading.jsx';
import { usePaymentMethodsContext } from '../../context/methodsPayments.js';

const DetailProduct = () => {
  const { pid } = useParams();
  const { fetchProductByName, product, loading } = useProductContext();
  const { addItem } = useCart();
  const { paymentMethods } = usePaymentMethodsContext();
  const [buttonText, setButtonText] = useState("Agregar al carrito");

  useEffect(() => {
    if (!product) {
      fetchProductByName(pid).catch((error) => {
        console.error("Error fetching product:", error);
      });
    }
  }, [pid, fetchProductByName, product]);

  const [selectedColor, setSelectedColor] = useState('');
  const [selectedTail, setSelectedTail] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState('');

  useEffect(() => {
    // Seleccionar automáticamente el primer color y talle si solo hay uno disponible
    if (product) {
      if (product.colors && product.colors.length === 1) {
        setSelectedColor(product.colors[0]);
      }
      if (product.tails && product.tails.length === 1) {
        setSelectedTail(product.tails[0]);
      }
    }
  }, [product]);

  if (loading) return <Loading message="Cargando productos, por favor espera..." />;
  if (!product && !loading) return <h2>Producto no encontrado</h2>;

  const formatColorName = (color) => {
    return color.replace(/([a-z])([A-Z])/g, '$1 $2');
  };
  
  const handleColorChange = (e) => {
    setSelectedColor(e.value);
    setError('');
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddToCart = () => {
    if (!selectedColor || !selectedTail) {
      setError('Por favor selecciona un color y un talle antes de agregar al carrito');
      return;
    }

    setButtonText("Agregando...");

    const productToAdd = {
      productID: product._id,
      title: product.title,
      price: product.discountPrice || product.price,
      description: product.subcategory,
      category: product.category,
      colorSelected: selectedColor,
      tailSelected: selectedTail,
      quantity: quantity,
    };

    addItem(productToAdd);

    setTimeout(() => {
      setButtonText("Listo! Revisá tu carrito");
      toast.success('Producto agregado al carrito con éxito.');

      setTimeout(() => {
        setButtonText("Agregar al carrito");
      }, 2000);
    }, 1000);
  };

  return (
    <section className="section-detail-item">
      <ToastContainer className="toast-container" />

      <BreadCrum />

      <div className="detail-product-container">
        <div className="product-gallery-container">
          <ProductGallery producto={product} />
        </div>

        <div className="details-section">
          <h1 className="product-name">{product.title}</h1>

          <div className="product-price">
            {product.discountPrice ? (
              <>
                <p className="product-price-discount">
                  {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.discountPrice)}
                </p>
                <p className="product-price-original">
                  <s>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price)}</s>
                </p>
              </>
            ) : (
              <p className="product-price-unico">
                {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price)}
              </p>
            )}
          </div>

          <p className="product-description">{product.description || 'Descripción no disponible'}</p>

          {product.colors && product.colors.length > 0 && (
            <div className="color-selector">
              <h4>Colores disponibles:</h4>
              <Dropdown
                value={selectedColor}
                onChange={handleColorChange}
                options={product.colors.map((color) => ({
                  label: formatColorName(color), // Aplica la función para formatear los nombres
                  value: color // Mantén el valor original
                }))}
                placeholder="Seleccionar color"
                className="w-full md:w-14rem custom-dropdown"
                disabled={product.colors.length === 1} 
              />
            </div>
          )}

          {product.tails && product.tails.length > 0 && (
            <div className="tail-selector">
              <h4>Talles disponibles:</h4>
              <div className="tail-options">
                {product.tails.map((talle) => (
                  <button
                    key={talle}
                    onClick={() => setSelectedTail(talle)}
                    className={`tail-button ${selectedTail === talle ? 'selected' : ''}`}
                    disabled={product.tails.length === 1} // Deshabilitar el botón si hay solo un talle
                  >
                    {talle}
                  </button>
                ))}
              </div>
            </div>
          )}

          <div className="quantity-selector">
            <h4>Cantidad:</h4>
            <input
              type="number"
              value={quantity}
              min="1"
              onChange={handleQuantityChange}
              className="quantity-input"
            />
          </div>

          {error && <p className="error-message">{error}</p>}

          <button
            onClick={handleAddToCart}
            className={`add-to-cart-button ${!selectedColor || !selectedTail ? 'disabled' : ''}`}
            disabled={buttonText === 'Agregando...'}
          >
            {buttonText}
          </button>
        </div>
      </div>

      <div className="info-pay-detail">
        <div className="security-message">
          <h3>Información de Seguridad de Pago</h3>
          <p>
            🔒 Esta es una página segura que utiliza SSL/TLS (Secure Socket Layer/Transport Layer Security) para cifrar y transmitir sus datos de pago de forma segura. Mercado Pago utiliza protocolos de encriptación y cumple con los estándares de seguridad de la industria para asegurar que cada transacción sea segura y confiable.
          </p>
        </div>

        <div className="preview-methods">
          {paymentMethods.map((method) => (
            <li key={method.id}>
              <img className="image-method-preview" src={method.thumbnail} alt={method.name} />
            </li>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DetailProduct;
