import React, { useEffect, useState } from 'react';
import FilterDesktop from "./FilterDesktop.jsx";
import FilterMobile from "./FilterMobile.jsx";

const Filter = ({ categories }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile ? (
    <FilterMobile categories={categories} />
  ) : (
    <FilterDesktop categories={categories} />
  );
};

export default Filter;