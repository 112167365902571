import React, { useState, useEffect } from "react";
import '../../styles/admin/modifyProduct.css';
import useUpdateProduct from "../../hooks/admin/UseUpdateProduct.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModifyProduct = ({ product, onSave, onCancel }) => {
    const { updateProduct, loading, error } = useUpdateProduct();

    const [currentProduct, setCurrentProduct] = useState({
        title: product.title || '',
        price: product.price || 0,
        discountPrice: product.discountPrice || 0, 
        category: product.category || '',
        subcategory: product.subcategory || '',
        description: product.description || '',
        images: product.images || [],
        colors: product.colors || [],
        tails: product.tails || [],
        stock: product.stock || 0,
        position: product.position || '' // Nuevo campo agregado
    });

    const [imagePreviews, setImagePreviews] = useState(product.images || []);
    const [newImages, setNewImages] = useState([]);

    useEffect(() => {
        if (error) {
            toast.error(`Error al actualizar el producto: ${error.message}`);
        }
    }, [error]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'images') {
            const filesArray = Array.from(files);
            setNewImages((prevImages) => [...prevImages, ...filesArray]);

            filesArray.forEach((file) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImagePreviews((prevPreviews) => [...prevPreviews, e.target.result]);
                };
                reader.readAsDataURL(file);
            });
        } else if (name === 'price' || name === 'discountPrice') {
            setCurrentProduct((prevProduct) => ({
                ...prevProduct,
                [name]: parseFloat(value.replace(/\./g, '').replace(',', '.')),
            }));
        } else if (name === 'colors' || name === 'tails') {
            setCurrentProduct((prevProduct) => ({
                ...prevProduct,
                [name]: value.split(',').map((item) => item.trim()),
            }));
        } else {
            setCurrentProduct((prevProduct) => ({
                ...prevProduct,
                [name]: value,
            }));
        }
    };

    const handleRemoveImage = (index) => {
        setCurrentProduct((prevProduct) => ({
            ...prevProduct,
            images: prevProduct.images.filter((_, i) => i !== index),
        }));
        setImagePreviews(imagePreviews.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.keys(currentProduct).forEach((key) => {
            if (key === 'images') {
                newImages.forEach((image) => formData.append('images', image));
            } else if (Array.isArray(currentProduct[key])) {
                currentProduct[key].forEach((item) => formData.append(key, item));
            } else {
                formData.append(key, currentProduct[key]);
            }
        });

        try {
            await updateProduct(product._id, formData);
            toast.success('Producto actualizado exitosamente!');
            onSave(currentProduct);
        } catch (error) {
            console.error('Error al actualizar el producto:', error);
        }
    };

    return (
        <div className="modify-product-container">
            <h3 className="title-modify">Modificar producto</h3>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-group">
                    <label>Título:</label>
                    <input type="text" name="title" value={currentProduct.title} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Precio:</label>
                    <input type="text" name="price" value={currentProduct.price} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Precio con Descuento:</label>
                    <input type="text" name="discountPrice" value={currentProduct.discountPrice} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Categoría:</label>
                    <input type="text" name="category" value={currentProduct.category} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Sub categoría:</label>
                    <input type="text" name="subcategory" value={currentProduct.subcategory} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Descripción:</label>
                    <textarea name="description" rows="4" value={currentProduct.description} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Colores (separados por coma):</label>
                    <input type="text" name="colors" value={currentProduct.colors.join(', ')} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Talles (separados por coma):</label>
                    <input type="text" name="tails" value={currentProduct.tails.join(', ')} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Stock:</label>
                    <input type="number" name="stock" value={currentProduct.stock} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Posición:</label>
                    <input type="text" name="position" value={currentProduct.position} onChange={handleInputChange} required />
                </div>
                <div className="form-group">
                    <label>Imágenes actuales:</label>
                    <div className="image-previews">
                        {imagePreviews.map((image, index) => (
                            <div key={index} className="image-preview">
                                <img src={image} alt={`Imagen actual ${index}`} />
                                <button type="button" onClick={() => handleRemoveImage(index)}>Eliminar</button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form-group">
                    <label>Agregar nuevas imágenes:</label>
                    <input type="file" name="images" onChange={handleInputChange} accept="image/*" multiple />
                </div>
                <div className="form-group">
                    <button type="submit" disabled={loading}>
                        {loading ? 'Actualizando...' : 'Actualizar Producto'}
                    </button>
                    <button type="button" onClick={onCancel}>Cancelar</button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default ModifyProduct;
