import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/breadCrum/breadCrum.css';

const BreadCrum = () => {
  const location = useLocation();

  // Definir las rutas conocidas
  const routeNames = {
    '/': 'Home',
    '/productos': 'Productos',
    '/checkout': 'Pago',
    '/faqs': 'Preguntas frecuentes',
  };

  // Filtrar y procesar las partes de la URL
  const pathParts = location.pathname.split('/').filter((part) => part && !/\d/.test(part));
  const lastValidIndex = pathParts.length - 1;

  return (
    <div className="bread-crum">
      <Link to="/" className="bread-home">
        Home <em className="em-home"> / </em>
      </Link>

      {pathParts.map((part, index) => {
        const path = `/${pathParts.slice(0, index + 1).join('/')}`;
        const routeLabel = routeNames[path] || part.replace(/-/g, ' ');

        if (index === lastValidIndex) {
          return (
            <span key={index} className="bread-current">
              {routeLabel}
            </span>
          );
        }

        return (
          <Link key={index} to={path} className="bread-link">
            {routeLabel}
            <em className="em-later"> / </em>
          </Link>
        );
      })}
    </div>
  );
};

export default BreadCrum;
