import React, { useState } from 'react';
import InstallmentOptions from './InstallmentOptions';
import { usePaymentMethodsContext } from '../../context/methodsPayments.js';

const PaymentMethods = ({ totalPrice }) => {
  const { paymentMethods, isLoading, error } = usePaymentMethodsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedMethodId, setExpandedMethodId] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleInstallment = (methodId) => {
    setExpandedMethodId(prev => (prev === methodId ? null : methodId));
  };
  
  if (isLoading) {
    return <p>Cargando métodos de pago...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <p className="methods-pay-p">Formas de pago disponibles</p>
      <em className="em-checkout">
        Calculá tu costo de envío llenando el formulario antes de ver las cuotas.
      </em>

      <div className="preview-methods">
        {paymentMethods.map((method) => (
          <li key={method.id}>
            <img className="image-method-preview" src={method.thumbnail} alt={method.name} />
          </li>
        ))}
      </div>

      <div className="view-installments-link">
        <button onClick={toggleModal}>Ver tarjetas y cuotas disponibles</button>
      </div>

      {/* Contenedor Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content-methods">
            <ul className="container-methods">
              {paymentMethods.map((method) => (
                <li key={method.id}>
                  <img className="image-method" src={method.thumbnail} alt={method.name} />
                  <span>{method.name}</span>
                  <a onClick={() => toggleInstallment(method.id)} className="view-installments-button">
                    {expandedMethodId === method.id ? 'Ocultar Cuotas' : 'Ver Cuotas'}
                  </a>

                  {/* Contenido expandible para las cuotas */}
                  {expandedMethodId === method.id && (
                    <InstallmentOptions methodId={method.id} amount={totalPrice} />
                  )}
                </li>
              ))}
            </ul>
            <button onClick={toggleModal} className="close-modal-button">Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
