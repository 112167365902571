import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useDeleteProduct = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteProduct = async (productId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/productos/${productId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error al eliminar el producto');
      }

      setLoading(false);
      return response.json();
    } catch (error) {
      setLoading(false);
      setError(error.message);
      throw error;
    }
  };

  return { deleteProduct, loading, error };
};

export default useDeleteProduct;