

export const PAYPALCLIENTID = process.env.PAYPALCLIENTID

export const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

export const HOST_IMG = '/statics/photos/'

export const REACT_APP_HOST_HOOKS_DEV = process.env.REACT_APP_HOST_HOOKS_DEV

export const REACT_APP_HOST_HOOKS_PROD = process.env.HOST_HOOKS_PROD

export const REACT_APP_ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN