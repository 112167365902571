import React, { createContext, useContext } from 'react';
import usePaymentMethods from '../hooks/methodsPayments/usePaymentMethods.js';

const PaymentMethodsContext = createContext();

export const PaymentMethodsProvider = ({ children }) => {
  const paymentMethodsData = usePaymentMethods();

  return (
    <PaymentMethodsContext.Provider value={paymentMethodsData}>
      {children}
    </PaymentMethodsContext.Provider>
  );
};

export const usePaymentMethodsContext = () => {
  return useContext(PaymentMethodsContext);
};
