import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAdminAddProduct from '../../hooks/admin/UseAdminAddProduct.js';
import '../../styles/admin/adminAddProduct.css';

const AddProduct = () => {
  const [currentProduct, setCurrentProduct] = useState({
    title: '',
    price: 0,
    discountPrice: 0,
    category: '',
    subcategory: '',
    description: '',
    images: [],
    colors: [],
    tails: [],
    posicion: 0,
    stock: 0,
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const { addProduct, isLoading } = useAdminAddProduct();

  const formatPrice = (value) => {
    if (value === '') return '';
    const cleanedValue = value.replace(/\D/g, '');
    const formattedValue = Number(cleanedValue).toLocaleString('es-AR');
    return formattedValue;
  };

  const parsePrice = (value) => {
    if (!value) return 0; 
    return Number(value.replace(/\./g, '').replace(',', '.'));
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'images') {
      const filesArray = Array.from(files);
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        images: [...prevProduct.images, ...filesArray],
      }));

      filesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, e.target.result]);
        };
        reader.readAsDataURL(file);
      });
    } else if (name === 'price' || name === 'discountPrice') { 
      const formattedPrice = formatPrice(value);
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        [name]: formattedPrice, 
      }));
    } else if (name === 'colors' || name === 'tails') {
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value.split(',').map((item) => item.trim()),
      }));
    } else if (name === 'posicion') {
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        posicion: parseInt(value, 10) || 0,
      }));
    }  else {
      setCurrentProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', currentProduct.title);
    formData.append('price', parsePrice(currentProduct.price));
    formData.append('discountPrice', parsePrice(currentProduct.discountPrice));
    formData.append('category', currentProduct.category);
    formData.append('subcategory', currentProduct.subcategory);
    formData.append('description', currentProduct.description);
    formData.append('posicion', currentProduct.posicion);
    formData.append('stock', currentProduct.stock);
    currentProduct.colors.forEach((color) => formData.append('colors', color));
    currentProduct.tails.forEach((tail) => formData.append('tails', tail));
    currentProduct.images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      await addProduct(formData);
      toast.success('Producto agregado exitosamente!');
      resetForm();
    } catch (error) {
      toast.error(`Error al agregar el producto: ${error.message}`);
    }
  };

  const resetForm = () => {
    setCurrentProduct({
      title: '',
      price: 0,
      discountPrice: 0, 
      category: '',
      subcategory: '',
      description: '',
      images: [],
      colors: [],
      tails: [],
      posicion: 0,
      stock: 0,
    });
    setImagePreviews([]);
  };

  return (
    <>
      <form className="add-product-form" onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label className="form-label" htmlFor="title">Nombre:</label>
          <input
            className="form-input"
            type="text"
            id="title"
            name="title"
            value={currentProduct.title}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="price">Precio (ARS):</label>
          <input
            className="form-input"
            type="text"
            id="price"
            name="price"
            value={currentProduct.price}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="discountPrice">Precio con Descuento (ARS):</label>
          <input
            className="form-input"
            type="text"
            id="discountPrice"
            name="discountPrice"
            value={currentProduct.discountPrice}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="category">Categoría:</label>
          <input
            className="form-input"
            type="text"
            id="category"
            name="category"
            value={currentProduct.category}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="description">Descripción:</label>
          <textarea
            className="form-input"
            id="description"
            name="description"
            value={currentProduct.description}
            onChange={handleInputChange}
            required
            rows="4"
          />
        </div>
        <div className="form-group">
        <label className="form-label" htmlFor="subcategory">Subcategoría:</label>
          <input
            className="form-input"
            type="text"
            id="subcategory"
            name="subcategory"
            value={currentProduct.subcategory}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="colors">Colores (separados por coma):</label>
          <input
            className="form-input"
            type="text"
            id="colors"
            name="colors"
            value={currentProduct.colors.join(', ')}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="tails">Talles (separados por coma):</label>
          <input
            className="form-input"
            type="text"
            id="tails"
            name="tails"
            value={currentProduct.tails.join(', ')}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="posicion">Posición:</label>
          <input
            className="form-input"
            type="number"
            id="posicion"
            name="posicion"
            value={currentProduct.posicion}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="stock">Stock:</label>
          <input
            className="form-input"
            type="number"
            id="stock"
            name="stock"
            value={currentProduct.stock}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="images">Imágenes:</label>
          <input
            className="form-input"
            type="file"
            id="images"
            name="images"
            onChange={handleInputChange}
            accept="image/*"
            multiple
            required
          />
        </div>
        <div className="image-previews">
          {imagePreviews.map((image, index) => (
            <img key={index} src={image} alt={`Previsualización ${index}`} />
          ))}
        </div>

        <button className="submit-button" type="submit" disabled={isLoading}>
          {isLoading ? 'Agregando...' : 'Agregar Producto'}
        </button>
      </form>
      <ToastContainer />
    </>
  );
};

export default AddProduct;
