import { useState, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../../config/config.js';

const useTransaction = () => {
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/transactions`, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Error fetching current user');
        }

        const data = await response.json();

        setTransactions(data);
        setLoading(false);
  
      } catch (error) {
        console.error('Error fetching profile:');
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return { transactions, loading };
};

export default useTransaction;