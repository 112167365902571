import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../../styles/loading/loading.css'

const Loading = ({ message = "Cargando..." }) => {
   return (
      <div className="loading-container">
         <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="transparent" animationDuration=".5s" />
         <p>{message}</p>
      </div>
   );
};

export default Loading;