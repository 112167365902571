import React, { createContext, useState, useContext, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

export const FeaturedProductsContext = createContext();

export const useFeaturedProducts = () => useContext(FeaturedProductsContext);

export const FeaturedProductsProvider = ({ children }) => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/admin/featured-products`)
      .then(response => response.json())
      .then(data => setFeaturedProducts(data))
      .catch(error => console.error('Error fetching featured products:', error));
  }, []);

  const addFeaturedProduct = (product) => {
    if (!featuredProducts.some(p => p._id === product._id)) {
      fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/admin/featured-products`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      })
        .then(response => response.json())
        .then(newProduct => setFeaturedProducts(prevProducts => [...prevProducts, newProduct]))
        .catch(error => console.error('Error adding featured product:', error));
    }
  };

  const removeFeaturedProduct = (productId) => {
    fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/admin/featured-products/${productId}`, {
      method: 'DELETE',
    })
      .then(() => setFeaturedProducts(prevProducts => prevProducts.filter(p => p._id !== productId)))
      .catch(error => console.error('Error removing featured product:', error));
  };

  const clearFeaturedProducts = () => {
    setFeaturedProducts([]);
  };

  return (
    <FeaturedProductsContext.Provider value={{ featuredProducts, addFeaturedProduct, removeFeaturedProduct, clearFeaturedProducts }}>
      {children}
    </FeaturedProductsContext.Provider>
  );
};