import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/navbar/navbar.css';
import Filter from '../filter/Filter';
import { GiShoppingCart } from "react-icons/gi";
import CartDrawer from './CartDrawer.jsx';
import { useCart } from '../../context/cartContext.js';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

function Navbar({ categories }) {
  const { cartQuantity } = useCart();
  const [isScrolled, setIsScrolled] = useState(false);
  const cartIconRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Detectar si es móvil

  // Manejar cambio de tamaño de pantalla
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsScrolled(offset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* CART */
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  return (
    <>
      <div className='banner-container'>
        <p>Promociones 20% OFF | 3 cuotas sin interés | Envío gratis a Bragado</p>
      </div>
      <section className={`header-container ${isScrolled ? 'header-container-scrolled' : ''}`}>

        <div className={`header-nav`}>

          <div>
            <nav className={`navbar ${isScrolled ? 'black' : ''}`}>

              {isMobile && <Filter categories={categories} />}

              <div className="logo">
                <Link to={'/'}>
                  <img
                    src={'/logotypeIndisNegroRemove.webp'}
                    className={`logotype ${isScrolled ? 'logotype-scrolled' : ''}`}
                    alt="logotipo"
                  />
                </Link>
              </div>

              <div className="nav-links-container">
                <ul className={`nav-links`}>
                  <li className='links-nav links-social'>
                    <a href="https://wa.me/5492342514576" target="_blank" rel="noopener noreferrer" className="whatsapp">
                      <FaWhatsapp size={20} />
                    </a>
                    <a href="https://www.instagram.com/indisindumentaria" target="_blank" rel="noopener noreferrer" className="instagram">
                      <FaInstagram size={20} />
                    </a>
                  </li>

                  <div className={`cart-icon ${isScrolled ? 'cart-icon-scrolled' : ''}`} onClick={toggleCart} ref={cartIconRef}>
                    <div className={`icon-carrito`}>
                      <GiShoppingCart />
                    </div>

                    <div className={`notification ${isScrolled ? 'notification-scrolled' : ''}`}>
                      {cartQuantity > 0 && <span>{cartQuantity}</span>}
                    </div>
                  </div>

                  <div className='itemCart'>
                    <CartDrawer isOpen={isCartOpen} onClose={toggleCart} cartIconRef={cartIconRef} />
                  </div>
                </ul>
              </div>


            </nav>
          </div>

          <div className='container-down-nav'>


            {!isMobile && (
              <div className='menu-hamburguer'>
                <Filter categories={categories} />
              </div>
            )}


          </div>
        </div>



      </section>
    </>

  );
}

export default Navbar;
