import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/success/SuccessPage.css';

const SuccessPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
      const fetchData = async () => {
          try {
              setIsLoading(true);

              const params = new URLSearchParams(window.location.search);

              const paymentId = params.get('payment_id');

              const response = await fetch(`/api/mercado-pago/capture-order?payment_id=${paymentId}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              if (!response.ok) {
                  throw new Error(`Error en la solicitud: ${response.status}`);
              }
              
              const data = await response.json();

              console.log(data,'data')
              setData({
                idTransaction: data.idTransaction,
                carrito: data.carrito,
                email: data.email,
                total: data.total
              });

              setEmailSent(true);
              setIsLoading(false);
          } catch (error) {
              console.error('Error al obtener el carrito', error);
              setIsLoading(false);
          }
      };
      fetchData();
  }, []);

  return (
    <section>
        <div className='backgoundSuccess'>
            <h2 className='h2-success'>¡Compra realizada con éxito!</h2>
        </div>

        <section className="success-page-section">

          <div className='container-padre-success'>

            <div>
              {isLoading ? (
                  <p>Cargando detalles de la compra...</p>
                ) : data?.carrito ? (
                    <>
                  <div className='ticket'>
                    <h4>Detalles de la compra</h4>

                    <p><strong>Número de reclamo:</strong> {data.idTransaction}</p>
                    
                    <ul>
                      {data.carrito.map((item, index) => (
                        <li key={index}>
                          <strong>Producto:</strong> {item.title} <br />
                          <strong>Descripción:</strong> {item.description} <br />
                          <strong>Cantidad:</strong> {item.quantity} <br />
                          <strong>Precio unitario:</strong> ${item.unit_price} <br />
                        </li>
                      ))}
                    </ul>

                    <p><strong>Total:</strong> ${data.total}</p>
                    
                  </div>
                </>
              ) : (
                <h2 className='failed-transaction'>No hay datos de compra.</h2>
              )}
            </div>

            <div>
              <h3 className='p-success'>¡Gracias por tu compra!</h3>
              {emailSent && 
              <p className='p-success'>
                <span role="img" aria-label="success" style={{ color: 'green', marginRight: '8px' }}>✔️</span>
                También te enviamos la confirmación de la compra al correo electrónico <em className='data-email-client'>{data?.email}</em>
              </p>}
              
              <p className="p-success">
                <span role="img" aria-label="envío" style={{ marginRight: '8px' }}>📦</span>
                Una vez despachado el producto, te vamos a mandar un mail y un WhatsApp con el código de seguimiento.
              </p>        

              <p className='p-success'>Si hay algún problema comunicate por WhatsApp.</p>
                
              <img 
                  src={`logotypeRedondos.webp`} 
                  alt="Logo de la empresa" 
                  className="company-logo"
              />

              <Link to="/" className="home-link">
                  Volver a Inicio
              </Link>
            
            </div>

          </div>


        </section>
       
    </section>

  );
};
  export default SuccessPage;