import { useState, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useProducts = (category, subcategory, page, limit, selectedColors = [], sortOrder) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      
      try {
        const queryParams = new URLSearchParams({
          page,
          limit,
          category: category || '',
          subcategory: subcategory || '',
          colors: selectedColors.length > 0 ? selectedColors.join(',') : '', 
          sort: sortOrder !== 'Predeterminado' ? sortOrder : '',
        });

        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/productos?${queryParams}`);
        if (!response.ok) {
          throw new Error('Error fetching products');
        }
        const data = await response.json();

        setProducts(data.docs);
        setTotalRecords(data.totalDocs);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching products:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category, page, subcategory, limit, selectedColors, sortOrder]);

  return { products, loading, error, totalRecords };
};

export default useProducts;
