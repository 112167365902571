import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useCartApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Obtener un carrito por ID
  const getCart = async (cartId) => {
    setLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carrito/${cartId}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // Crear un nuevo carrito
  const createCart = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carrito`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setLoading(false);
      return data; 
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // Agregar un producto al carrito
  const addToCart = async (cartId, product) => {
    setLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carrito/${cartId}/producto`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // Eliminar un producto del carrito
  const removeFromCart = async (cartId, productId) => {
    setLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carrito/${cartId}/producto/${productId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setLoading(false);
      return data;
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  // Limpiar el carrito
  const clearCart = async (cartId) => {
    setLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/carrito/${cartId}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return {
    getCart,
    addToCart,
    removeFromCart,
    clearCart,
    createCart,
    loading,
    error,
  };
};

export default useCartApi;
