import React, { useState } from 'react';
import { useCart } from '../../context/cartContext';
import DataForm from './DataForm';
import SummaryContainer from './SummaryContainer';
import '../../styles/checkout/checkout.css';

const Checkout = () => {
  const { cart } = useCart();
  const [personalInfo, setPersonalInfo] = useState({
    name: '', 
    lastName: '', 
    email: '', 
    areaCode: '', 
    phoneNumber: '',
    dni: ''
  });
  const [shippingInfo, setShippingInfo] = useState({
    street: '', 
    number: '', 
    floor: '', 
    apartment: '', 
    city: '', 
    province: '', 
    postalCode: ''
  });
  const [billingInfo, setBillingInfo] = useState({
    street: '', 
    number: '', 
    floor: '', 
    apartment: '', 
    city: '', 
    province: '', 
    postalCode: ''
  });

  const isFormComplete = () => {
    return (
      personalInfo.name && personalInfo.lastName && personalInfo.email &&
      personalInfo.areaCode && personalInfo.phoneNumber &&
      shippingInfo.street && shippingInfo.number && shippingInfo.city && 
      shippingInfo.province && shippingInfo.postalCode
    );
  };

  return (
    <section>


      <div className="checkout-container">
        {/* Formulario de Checkout */}
        <DataForm
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          shippingInfo={shippingInfo}
          setShippingInfo={setShippingInfo}
          billingInfo={billingInfo}
          setBillingInfo={setBillingInfo}
          isFormComplete={isFormComplete}
          carrito={cart}
        />

        {/* Resumen del carrito */}
        <SummaryContainer cart={cart} />
      </div>
    </section>
  );
};

export default Checkout;
