import { useEffect, useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/mercado-pago/payment-methods`, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener los métodos de pago');
        }
        const data = await response.json();
        setPaymentMethods(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentMethods();
  }, []);

  return { paymentMethods, isLoading, error };
};

export default usePaymentMethods;
