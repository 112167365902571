import React from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import '../../styles/footer/footer.css'; 

const Footer = () => {
    return (
      <footer>
        <div className="footer-content">
          <p>&copy; 2024 INDIS Ind. All rights reserved.</p>
          <div className="social-icons">
            <a href="https://www.instagram.com/indisindumentaria" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://wa.me/5492342514576" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;