import React, { useEffect, useState } from 'react';
import '../../styles/shop/shop.css';
import Item from '../Item/Item';
import { useParams } from 'react-router-dom';
import BreadCrum from '../breadCrum/BreadCrum';
import { useProductContext } from '../../context/ProductContext.js';
import { Paginator } from 'primereact/paginator';
import Loading from '../loading/Loading.jsx'; 
import ScrollToTop from '../scrollToTop/scrollToTop.jsx'

const Shop = () => {
  const { category } = useParams(); 
  const { subcategory } = useParams(); 
  const { products, loading, error, totalRecords, page, setPage, setCategory, setSubcategory, setSelectedColors, setSortOrder } = useProductContext();

  const colors = ['Blanco', 'Verde', 'Azul', 'Beige', 'Negro'];
  const sortOptions = ['Predeterminado', 'Menor a mayor precio', 'Mayor a menor precio'];

  const [showColorOptions, setShowColorOptions] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCategory(category);
      setSubcategory(subcategory || null);
    }, 500);

    return () => clearTimeout(timer); 
  }, [category, subcategory, setCategory, setSubcategory]);


  const onPageChange = (e) => {
    const newPage = e.page + 1;
    setPage(newPage);
  };

  const handleColorChange = (color) => {
    setSelectedColors((prev) => {
      if (prev.includes(color)) {
        return prev.filter(c => c !== color);
      }
      return [...prev, color];
    });
  };

  const handleSortChange = (option) => {
    setSortOrder(option);
    setShowSortOptions(false); 
  };

  if (loading) return <Loading message="Cargando productos, por favor espera..." />; 

  if (error) return <p>Error al cargar productos: {error}</p>;

  return (
    <div className="shop-container">
      <ScrollToTop />

      <BreadCrum />
      
      <div className="filter-container-list">
        <p className="filter-bold">FILTRAR: </p>

        <div className="color-filter" onClick={() => setShowColorOptions(!showColorOptions)}>
          <p>
            COLOR
            <span className={`arrow ${showColorOptions ? 'up' : 'down'}`}>&#9660;</span>
          </p>

          {showColorOptions && (
            <div className="color-options">
              {colors.map((color) => (
                <label key={color}>
                  <input
                    type="checkbox"
                    value={color}
                    onChange={() => handleColorChange(color)}
                  />
                  {color}
                </label>
              ))}
            </div>
          )}
        </div>

        <div className="sort-filter" onClick={() => setShowSortOptions(!showSortOptions)}>
          <p>
            ORDENAR POR
            <span className={`arrow ${showSortOptions ? 'up' : 'down'}`}>&#9660;</span>
          </p>

          {showSortOptions && (
            <div className="sort-options">
              {sortOptions.map((option) => (
                <div 
                  key={option} 
                  className="sort-option"
                  onClick={() => handleSortChange(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <section className="product-grid">
        {products.map(product => (
          <Item key={product.id} product={product} />
        ))}
      </section>

      <div className='pagination'>
        <Paginator 
          first={(page - 1) * 10}
          rows={10}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>

    </div>
  );
};

export default Shop;
