import React, { useState, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config';

const InstallmentOptions = ({ methodId, amount }) => {
  const [installments, setInstallments] = useState([]);

  useEffect(() => {
    const fetchInstallments = async () => {
      try {
        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/mercado-pago/installments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            method_id: methodId,
            amount: amount
          })
        });
        const data = await response.json();
        
        setInstallments(data[0]?.payer_costs || []);
      } catch (error) {
        console.error('Error al obtener cuotas:', error);
      }
    };

    if (methodId && amount) {
      fetchInstallments();
    }
  }, [methodId, amount]);

  return (
    <div className="installments-info">
      <p>Cuotas disponibles para {methodId}:</p>
      <ul>
        {installments.map((installment, index) => (
          <li key={index}>{installment.recommended_message}</li>
        ))}
      </ul>
    </div>
  );
};

export default InstallmentOptions;
