import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/filter/filterDekstop.css';

/** Componente para pantallas de escritorio */
function FilterDesktop({ categories }) {
  const formatUrl = (text) => text.toLowerCase().replace(/\s+/g, '-');

  return (
    <ul className="filter-desktop-container">
      <li className="filter-desktop-item dropdown">
        Categorías
        <div className="filter-desktop-categories-container">
          {categories.map((parentCategory, index) => (
            <div key={index} className="filter-desktop-category-item dropdown">
              <Link
                to={`/productos/${formatUrl(parentCategory.name)}`}
                className="filter-desktop-category-link"
              >
                {parentCategory.name}
              </Link>
              <div className="filter-desktop-subcategories-container">
                {parentCategory.subcategories.map((subcategory, subIndex) => (
                  <Link
                    to={`/productos/${formatUrl(parentCategory.name)}/${formatUrl(subcategory)}`}
                    key={subIndex}
                    className="filter-desktop-subcategory-link"
                  >
                    {subcategory}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </li>
      <li>
        <Link to="/productos" className="filter-desktop-item">
          Todos los productos
        </Link>
      </li>

      <li>
        <Link to="/faqs" className="filter-desktop-item">
          FAQs
        </Link>
      </li>

    </ul>
  );
}

export default FilterDesktop;
