// src/components/FeaturedProductsSelector.jsx
import React, { useEffect, useState } from 'react';
import '../../styles/admin/featuredProducts.css';
import { useFeaturedProducts } from '../../context/featuredProducts.js';

const FeaturedProductsSelector = ({ products }) => {
  const { featuredProducts, addFeaturedProduct, removeFeaturedProduct } = useFeaturedProducts();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(products || []);

  useEffect(() => {
    if (Array.isArray(products)) {
      setFilteredProducts(
        products.filter(product =>
          product.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, products]);

  const toggleProductSelection = (product) => {
    if (featuredProducts.some(p => p._id === product._id)) {
      removeFeaturedProduct(product._id);
    } else if (featuredProducts.length < 50) {
      addFeaturedProduct(product);
    }
  };

  return (
    <div>
      <h2 className='title-featured-h2'>Seleccionar Productos Destacados</h2>
      <input
        type="text"
        placeholder="Buscar producto..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="products-list-admin">
        {Array.isArray(filteredProducts) && filteredProducts.map((product) => (
          <div
            key={product._id}
            className={`product-item ${featuredProducts.some(p => p._id === product._id) ? 'selected' : ''}`}
            onClick={() => toggleProductSelection(product)}
          >
            {product.title}
          </div>
        ))}
      </div>
      <div className="selected-products-container">
        <h3>Productos Destacados Seleccionados:</h3>
        <ul className='ul-featured'>
          {featuredProducts.map((product) => (
            <li key={product._id}>{product.title} - {product.category}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FeaturedProductsSelector;