import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import useCartApi from '../hooks/carritoHook/UseCart.js'; 

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState({ items: [], totalPrice: 0, shippingCost: 0 });
  const { getCart, addToCart, removeFromCart, clearCart, createCart  } = useCartApi();
  const [loadingCart, setLoadingCart] = useState(false);

  const fetchCart = async (cartId) => {
    setLoadingCart(true);
    const cartData = await getCart(cartId);
    if (cartData) {
      setCart(cartData);
    }
    setLoadingCart(false);
  };

  useEffect(() => {
    const storedCartId = localStorage.getItem('cart');
    if (storedCartId) {
      fetchCart(storedCartId);
    }
  }, []);

  const addItem = async (item) => {
    let storedCartId = localStorage.getItem('cart');

    setLoadingCart(true);
    
    if (!storedCartId) {
      const newCartId = await createCart();
      storedCartId = newCartId._id; 
      localStorage.setItem('cart', storedCartId);
    }

    await addToCart(storedCartId, item);
    
    refreshCart(storedCartId); 
  };

  const removeItem = async (itemId) => {
    setLoadingCart(true);
    const storedCartId = localStorage.getItem('cart');
    await removeFromCart(storedCartId, itemId);
    refreshCart(storedCartId);
  };

  const clearAll = async () => {
    setLoadingCart(true);
    const storedCartId = localStorage.getItem('cart'); 
    if (storedCartId) {
      await clearCart(storedCartId);
      setCart({ items: [], totalPrice: 0, shippingCost: 0 });
      localStorage.removeItem('cart');
    }
    setLoadingCart(false);
  };
  // Función para refrescar el carrito
  const refreshCart = async (cartId) => {
    const cartData = await getCart(cartId);
    if (cartData) {
      setCart(cartData);
    }
    setLoadingCart(false)
  };

  const updateShippingCost = (cost) => {
    setCart(prevCart => ({
      ...prevCart,
      totalPrice: prevCart.items.reduce((acc, item) => acc + item.price * item.quantity, 0) + cost,
      shippingCost: cost
    }));
  };


  const cartQuantity = useMemo(() => {
    return cart.items.length;
  }, [cart]);

  const value = {
    cart,
    addItem,
    removeItem,
    clearAll,
    loadingCart,
    cartQuantity,
    updateShippingCost,
    refreshCart,
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};
