import React, { useEffect, useState } from 'react';
import '../styles/homePage.css';

// Importar Swiper y los módulos necesarios
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Importar módulos de Swiper que se usarán
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// react-icons
import { FaCreditCard, FaTruck, FaStore } from 'react-icons/fa';

import { useFeaturedProducts } from '../context/featuredProducts.js';
import { Link } from 'react-router-dom';

const HomePage = ( {categories} ) => {
  const [isMobile, setIsMobile] = useState(false);

  // Detecta si el dispositivo es móvil
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Cambia el umbral si es necesario
    };

    // Comprobar al cargar la página
    checkMobile();

    // Agregar un listener para cambios de tamaño de ventana
    window.addEventListener('resize', checkMobile);

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const { featuredProducts } = useFeaturedProducts(); 


  const formatCategoryUrl = (category) => {
    return category.toLowerCase().replace(/\s+/g, '-');
  };

  const getProductLinkItem = (product) => {
    if (!product || !product._id || !product.title || !product.category) {
        return '#'; // Enlace vacío si el producto no tiene los datos esperados
    }

    const pid = product._id;
    const productName = product.title.replace(/\s+/g, '-').toLowerCase(); 
    const category = product.category.replace(/\s+/g, '-').toLowerCase(); 
    
    return `/productos/${category}/${productName}/${pid}`;
};

  return (
    <div className="homepage">

      {/* Hero Section */}
           
        <section className="hero-section">   
          <div className="hero-image hero-image-1">
            <h1>NUEVA COLECCIÓN VERANO 2025</h1> 
           <Link to={'/productos'}> 
            <img src='/frente1.jpeg' alt='modelo en el mar1'></img>
           </Link>  
          </div>

          <div className="hero-image hero-image-2">
            <Link to={'/productos'}> 
              <img src='/frente2.jpeg' alt='modelo en el mar2'></img>
            </Link>  
          </div>

        </section>

      
      <section className="categories-grid">
      <h2>CATEGORÍAS MÁS VISITADAS</h2>
        <div className="categories-container">
          {categories.map((category, index) => (
            <Link 
              key={index} 
              to={`/productos/${formatCategoryUrl(category.name)}`} 
              className="category-link-home"
            >
              <div className="category-card-home">
                <h3>{category.name}</h3>
              </div>
            </Link>
          ))}
        </div>
      </section>

      {/* Sección de Promociones */}
      <section className="promo-section">
        <div className="promo-item">
          {isMobile ? (
            <img src={'/promociones-celular.png'} alt="Promoción móvil" />
          ) : (
            <img src={'/promociones.png'} alt="Promoción desktop" />
          )}
        </div>
      </section>

      {/* Productos Destacados (con Swiper) */}
      <section className="featured-products-section">
        <h2>PRODUCTOS DESTACADOS</h2>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]} 
          spaceBetween={20}
          slidesPerView={1}
          navigation 
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }} 
          breakpoints={{
            300: { slidesPerView: 1 },
            600: { slidesPerView: 2 },
            800: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
        >
          {featuredProducts.map((product) => (
            <SwiperSlide key={product._id}>
              <Link to={getProductLinkItem(product)}>
                <div className="product-card-featured">
                  <img src={product.images[0]} alt={product.title} />
                  <h3>{product.title}</h3>
                  <p>${product.price}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      {/* Info section */}

      <section className="info-section">
        <h2>Información Importante</h2>
        <div className="info-grid">
          <div className="info-item">
            <FaCreditCard className="info-icon" />
            <h3>Métodos de Pago</h3>
            <p>Ofrecemos múltiples métodos de pago seguros, incluyendo tarjetas de crédito (Hasta 3 cuotas sin interés), débito y transferencias.</p>
          </div>
          <div className="info-item">
            <FaTruck className="info-icon" />
            <h3>Tiempos de Envío</h3>
            <p>Los envíos se procesan en un plazo de 4 a 10 días hábiles, dependiendo de tu ubicación.</p>
          </div>
          <div className="info-item">
            <FaStore className="info-icon" />
            <h3>Sin Local Físico</h3>
            <p>No contamos con un local físico, pero estamos aca para ayudarte en línea. ¡Contactanos para cualquier consulta!</p>
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="newsletter-section">
        <div className="newsletter-content">
          <h2>Suscribite a nuestro Newsletter</h2>
          <p>Recibí ofertas exclusivas y las últimas novedades directamente en tu correo.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Ingresá tu email" />
            <button className='btn-newsletter' type="submit">Suscribirse</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
