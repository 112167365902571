import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/authAdmin.js';
import '../../styles/login/loginModal.css'
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const { adminLogin } = useContext(AuthContext);

  const navigate = useNavigate();

  const login = (loginData) => {
    fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al iniciar sesión');
        }
        return response.json();
      })
      .then(data => {
        adminLogin();
        console.log('aca')
        navigate('/admin');
      })
      .catch(error => {
        console.error('Error al iniciar sesión:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(loginData);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          <div>
            <label>Usuario:</label>
            <input 
              className="input-user" 
              type="text" 
              name="username" 
              value={loginData.username} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div>
            <label>Contraseña:</label>
            <input 
              type="password" 
              name="password" 
              value={loginData.password} 
              onChange={handleChange} 
              required 
            />
          </div>
          <button className='btn-login' type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
