import React, { useEffect } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

//APP_USR-928b643c-9055-433e-8d2c-72ce617ba68e
const MercadoPagoWallet = ({ preferenceId, loading }) => {
  useEffect(() => {
    initMercadoPago('APP_USR-e858c34e-7364-4255-8ae4-abfea3323253', { locale: 'es-AR' });
  }, []);

  const handlePaymentClick = () => {
    const walletElement = document.getElementById("wallet_container");
    if (walletElement) {
      walletElement.click();
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-spinner">
          <p>Cargando..</p>
        </div>
      ) : (
        <button 
          id="wallet_container" 
          className="payment-btn mercado-pago-btn" 
          onClick={handlePaymentClick}
        >
          <Wallet 
            initialization={{ preferenceId, redirectMode: 'self' }} 
            customization={{ texts: { valueProp: 'smart_option' } }} 
          />
        </button>
      )}
    </div>
  );
};

export default MercadoPagoWallet;
