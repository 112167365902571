import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Item/item.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Item = ({ product }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (product && Array.isArray(product.images) && product.images.length > 0) {
            setImages(product.images.map(image => ({ itemImageSrc: image, alt: product.title || 'Imagen' })));
        }
    }, [product]);

    const getProductLink = () => {
        if (!product || !product._id || !product.title || !product.category) {
            return '#'; // Enlace vacío si el producto no tiene los datos esperados
        }

        const pid = product._id;
        const productName = product.title.replace(/\s+/g, '-').toLowerCase(); 
        const category = product.category.replace(/\s+/g, '-').toLowerCase(); 
        return `/productos/${category}/${productName}/${pid}`;
    };

    return (
        <div className="recommended-product-card" style={{ cursor: 'pointer' }}>
            <Link to={getProductLink()} className="product-link">
                <div className="image-gallery-container" onClick={(e) => e.stopPropagation()}>
                    {images.length > 0 ? (
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            loop
                            className="swiper-container"
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image.itemImageSrc} alt={image.alt || 'Producto'} className="recommended-product-image" />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <div>No hay imágenes disponibles</div>
                    )}
                </div>

                <div className="recommended-product-info">
                    <div className="content-price-title">
                        <div className="container-title-card">
                            <p className="recommended-product-title">{product.title}</p>
                        </div>
                        
                        <div className="content-price-card">
                            {product.discountPrice ? (
                                <>
                                    <p className="price-discount">
                                        {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.discountPrice || 0)}
                                    </p>
                                    <p className="product-price-original-item">
                                        <s>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price || 0)}</s>
                                    </p>
                                </>
                            ) : (
                                <p className="price-regular">
                                    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price || 0)}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Item;