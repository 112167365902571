import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useLogout = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const logout = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/session/current`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error al cerrar sesión');
      }

      window.location.replace("/")
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, logout };
};

export default useLogout;