import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/filter/FilterMobile.css';

/** Componente para pantallas móviles */
function FilterMobile({ categories }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(null);  // Añadido para manejar categorías abiertas
  
  const toggleMenu = () => setIsOpen(!isOpen);

  const toggleCategory = (index) => {
    setOpenCategory(openCategory === index ? null : index);  // Cambia la categoría abierta
  };

  const formatUrl = (text) => text.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="filter-mobile-container">
      <button
        className={`menu-toggle-button ${isOpen ? 'active' : ''}`}
        onClick={toggleMenu}
      >
        <span className="menu-bar" />
        <span className="menu-bar" />
        <span className="menu-bar" />
      </button>

      <ul className={`menu-dropdown ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={() => setIsOpen(false)}>
          <li className="menu-item">Inicio</li>
        </Link>
        <Link to="/productos" onClick={() => setIsOpen(false)}>
          <li className="menu-item">Todos los productos</li>
        </Link>
        
        {/* Categorías */}
        <li className="menu-item">
          <button className="category-toggle" onClick={() => toggleCategory('categories')}>
            Categorías
          </button>
          <ul className={`category-list ${openCategory === 'categories' ? 'open' : ''}`}>
            {categories.map((parentCategory, index) => (
              <li key={index} className="category-item">
                <Link
                  to={`/productos/${formatUrl(parentCategory.name)}`}
                  onClick={() => setIsOpen(false)}
                  className="category-link"
                >
                  {parentCategory.name}
                </Link>
                {/* Subcategorías */}
                {parentCategory.subcategories && (
                  <ul className="subcategory-list">
                    {parentCategory.subcategories.map((subcategory, subIndex) => (
                      <li key={subIndex} className="subcategory-item">
                        <Link
                          to={`/productos/${formatUrl(parentCategory.name)}/${formatUrl(subcategory)}`}
                          onClick={() => setIsOpen(false)}
                          className="subcategory-link"
                        >
                          {subcategory}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </li>
        
        <Link to="/faqs" onClick={() => setIsOpen(false)}>
          <li className="menu-item">FAQs</li>
        </Link>

        <li className="menu-item">
          <a
            href="https://wa.me/5492342514576"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-link"
          >
            Contacto
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FilterMobile;
