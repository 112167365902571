import React, { useRef } from 'react';
import '../../styles/navbar/cartDrawer.css';
import Cart from '../cart/Cart';


const CartDrawer = ({ isOpen, onClose }) => {
    const cartDrawerRef = useRef(null);

    return (
        <>

            {isOpen && <div className="overlay" onClick={onClose}></div>}           
            <div
                className={`cart-drawer ${isOpen ? 'open' : ''}`}
                ref={cartDrawerRef} 
            >
                <button className="close-btn" onClick={onClose}>×</button>
                <h3>MI CARRITO</h3>

                    <div className='container-cart'> 
                        <Cart onClose={onClose}/>
                    </div>
      
            </div>
        </>
    );
};

export default CartDrawer;