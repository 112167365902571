import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import "../../styles/detailProduct/productGallery.css";

const ProductGallery = ({ producto }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenIndex, setFullscreenIndex] = useState(0);

  const { images, title } = producto || {};
  const imagesToShow = Array.isArray(images) ? images.slice(0, 10) : [];

  const handleImageClick = (index) => {
    setFullscreenIndex(index);
    setIsFullscreen(true);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  return (
    <div className="galleria-container">
      {imagesToShow.length > 0 ? (
        <>
        
          {/* Galería principal */}
          <Swiper
            modules={[Navigation, Pagination, Zoom]}
            navigation
            pagination={{ clickable: true }}
            spaceBetween={10}
            slidesPerView={1}
            className="main-gallery"
          >
            {imagesToShow.map((image, index) => (
              <SwiperSlide key={index} onClick={() => handleImageClick(index)}>
                <img src={image} alt={title} className="galleria-image" />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Modal de pantalla completa */}
          {isFullscreen && (
            <div className="fullscreen-modal" onClick={closeFullscreen}>
              <Swiper
                modules={[Navigation, Zoom]}
                navigation
                zoom
                initialSlide={fullscreenIndex}
                spaceBetween={10}
                slidesPerView={1}
                className="fullscreen-gallery"
              >
                {imagesToShow.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img src={image} alt={`${title} - ${index}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <button className="close-button" onClick={closeFullscreen}>
                ✕
              </button>
            </div>
          )}
        </>
      ) : (
        <p>No hay imágenes disponibles para este producto.</p>
      )}
    </div>
  );
};

export default ProductGallery;
